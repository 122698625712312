import { apiStatus } from '@/enums'
import axiosIns from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

const getDefaultState = () => ({
  specifications: [],
  selectedSpecification: {},
  specifications_status: apiStatus.INIT,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    specifications_options: state => {
      if (state.specifications_status === apiStatus.LOADED) {
        return state.specifications.map(specification => Object({
          text: specification.title,
          value: specification.id,
          description: specification.description,
          additional_attributes: specification.additional_attributes,
          default: specification.default,
        }))
      } return []
    },
    specification: (state, specificationId) => state.specifications.find(({ id }) => id === specificationId),
  },
  mutations: {
    SET_SPECIFICATIONS: (state, specifications) => { state.specifications = specifications },
    SET_SPECIFICATIONS_STATUS: (state, status) => { state.specifications_status = status },
    SET_SPECIFICATION: (state, data) => { state.selectedSpecification = data },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    fetchSpecifications: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      commit('SET_SPECIFICATIONS_STATUS', apiStatus.LOADING)
      return axiosIns
        .get('/api/v2/specifications', { params })
        .then(({ data }) => {
          commit('SET_SPECIFICATIONS', data)
          commit('SET_SPECIFICATIONS_STATUS', apiStatus.LOADED)
        })
        .catch(e => console.error(e))
    },
    fetchSpecificationsSimple: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId, simple: true }
      commit('SET_SPECIFICATIONS_STATUS', apiStatus.LOADING)
      return axiosIns
        .get('/api/v2/specifications', { params })
        .then(({ data }) => {
          commit('SET_SPECIFICATIONS', data)
          commit('SET_SPECIFICATIONS_STATUS', apiStatus.LOADED)
        })
        .catch(e => console.error(e))
    },
    selectSpecification: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/specifications/${id}`, { params })
        .then(({ data }) => {
          commit('app/SET_DYNAMIC_PAGE_TITLE', data.title, { root: true })
          commit('SET_SPECIFICATION', data.specification.id)
        })
        .catch(e => console.error(e))
    },
    setAsDefaultSpecification({ state, dispatch }, specificationId) {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .patch(`/api/v2/specifications/${specificationId}/set_default`, { model: modelId })
        .then(() => {
          dispatch('selectSpecification', specificationId)
            .then(() => {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Changed default Specification',
                  text: `${state.selectedSpecification.specification.title} has been made the default specification`,
                  icon: 'TickIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    clearSpecifications: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
