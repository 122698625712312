<template>
<div>
  <p class="mt-0 mb-25 font-weight-bold text-primary">
    Operational Status
  </p>

  <div v-if="(operationalStatus && operationalStatus !== -1) || operationalStatus === 0" class="ml-50">
    <b-badge :class="resolveClass(operationalStatus)" id="op-sts-badge">
      {{ resolveOperationalStatus(operationalStatus).name }}
    </b-badge>
    <b-tooltip target="op-sts-badge" triggers="hover">
      {{ resolveOperationalStatus(operationalStatus).description }}
    </b-tooltip>
  </div>
  <div v-else class="ml-50">
    <b-badge class="unevaluated" id="op-sts-badge2">
      Unevaluated
    </b-badge>
    <b-tooltip target="op-sts-badge2" triggers="hover">
      Operational Status Unevaluated
    </b-tooltip>
  </div>
</div>
</template>

<script>
import store from '@/store'

export default {
  name: 'OperationalStatus',
  props: {
    operationalStatus: {
      type: Number,
      default: -1, // Unevaluated is -1
    },
  },
  setup(props, context) {
    const resolveOperationalStatus = value => store.state.constants.operationalStatus.find(o => o.id === value)
    const resolveClass = value => {
      if (value === -1) {
        return 'unevaluated'
      }
      if (value === 0) {
        return 'fullyOperational'
      }
      if (value === 1) {
        return 'degradedMinor'
      }
      if (value === 2) {
        return 'degradedSignificant'
      }
      if (value === 3) {
        return 'nonOperational'
      }
      return 'unevaluated'
    }

    return {
      resolveOperationalStatus,
      resolveClass,
    }
  },
}
</script>

<style scoped lang="scss">
.unevaluated {
  background-color: lightsteelblue;
  color: #263238 !important;
}

.fullyOperational {
  background-color: #28a745;
}

.degradedMinor {
  background-color: palegoldenrod;
  color: #263238 !important;
}

.degradedSignificant {
  background-color: #fd7e14;
}

.nonOperational {
  background-color: #dc3545;
}
</style>
