export class SnapshotsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Upload And Import Snapshot
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    uploadAndImportSnapshot(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/snapshots/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Convert Snapshot Into Live Model
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    convertSnapshotIntoLiveModel(snapshotId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/snapshots/{snapshot_id}/convert',
            path: {
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
