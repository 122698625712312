import Vue from 'vue'
import router from '@/router'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const getDefaultState = () => ({
  modal_target_note_id: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_GLOBAL_MODAL_NOTE_ID: (state, id) => { state.modal_target_note_id = id },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    setGlobalNoteId: ({ commit }, noteId) => { commit('SET_GLOBAL_MODAL_NOTE_ID', noteId) },
    fetchNote: ({ commit }, noteId) => {
      /**
       * Fetch the Note from the database and return with the data and etag value
       */
      const { modelId } = router.currentRoute.params
      const headers = { 'Model-Id': modelId }

      const note = { data: {}, etag: '' }
      return coreService.get(`/v1/note/${noteId.value}`, { headers })
        .then(response => {
          note.etag = response.headers.etag
          note.data = response.data
          return note
        })
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cannot load note',
              text: `${e}`,
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
          console.error(e)
          commit('SET_GLOBAL_MODAL_NOTE_ID', null)
          return null
        })
    },
    createNote: ({ commit }, data) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      commit('SET_GLOBAL_MODAL_NOTE_ID', true) // External components watch for this value to go from true -> false

      coreService.noteApi.createNote(modelId, data)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Note created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          resolve()
        })
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create note',
              text: `${e}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          console.error(e)
          reject(e)
        }).finally(() => commit('SET_GLOBAL_MODAL_NOTE_ID', null))
    }),
    updateNote: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      const { noteId, etag, data } = payload

      return coreService.noteApi.updateNote(noteId, modelId, etag, data)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Note updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          resolve(response)
        })
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update note',
              text: `${e}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          console.error(e)
          reject(e)
        }).finally(() => commit('SET_GLOBAL_MODAL_NOTE_ID', null))
    }),
    deleteNote: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      const { noteId, etag } = payload

      return coreService.noteApi.deleteNote(noteId, modelId, etag)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Note deleted',
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
          resolve(response)
        })
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete note',
              text: `${e}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          console.error(e)
          reject(e)
        })
        .finally(() => { commit('SET_GLOBAL_MODAL_NOTE_ID', null) })
    }),
    clearNotes: ({ commit }) => { commit('CLEAR_ALL') },
  },
}
