import store from '@/store'

export default function utilsDragAndDrop() {
  const registerDragAndDrop = () => {
    const items = document.querySelectorAll('.req-row')
    items.forEach(item => {
      item.addEventListener('dragstart', handleDragStart)
      item.addEventListener('dragover', handleDragOver)
      item.addEventListener('dragenter', handleDragEnter)
      item.addEventListener('dragleave', handleDragLeave)
      item.addEventListener('dragend', handleDragEnd)
      item.addEventListener('drop', handleDrop)
    })
  }

  const unregisterDragAndDrop = () => {
    const items = document.querySelectorAll('.req-row')
    items.forEach(item => {
      item.removeEventListener('dragstart', handleDragStart)
      item.removeEventListener('dragover', handleDragOver)
      item.removeEventListener('dragenter', handleDragEnter)
      item.removeEventListener('dragleave', handleDragLeave)
      item.removeEventListener('dragend', handleDragEnd)
      item.removeEventListener('drop', handleDrop)
    })
  }

  const calculateQuadrant = ev => {
    const rect = ev.currentTarget.getBoundingClientRect()
    const x = ev.clientX - rect.left // X position within the element.
    const y = ev.clientY - rect.top // Y position within the element.

    // Determine whether the X position is in the left or right half
    const horizontalHalf = (x < rect.width / 2) ? 'left' : 'right'
    // Determine whether the Y position is in the top or bottom half
    const verticalHalf = (y < rect.height / 2) ? 'top' : 'bottom'

    // Combine the two to get the quadrant
    return `${verticalHalf}-${horizontalHalf}`
  }

  const handleDragStart = ev => {
    ev.currentTarget.style.opacity = '0.4'
    const draggedReqId = ev.currentTarget.parentNode.id

    ev.dataTransfer.effectAllowed = 'move'
    ev.dataTransfer.setData('text', draggedReqId)
  }

  const handleDragEnd = ev => {
    ev.currentTarget.style.opacity = '1'
    removeClasses(ev)
  }

  const removeClasses = event => {
    const el = event.currentTarget
    el.classList.remove('draggedOver')
    el.classList.remove('topLeft')
    el.classList.remove('topRight')
    el.classList.remove('bottomLeft')
    el.classList.remove('bottomRight')
  }

  const handleDragOver = ev => {
    ev.preventDefault()
    const element = ev.currentTarget
    if (!element.classList.contains('req-row')) { return false }

    removeClasses(ev)

    const source = ev.dataTransfer.getData('text')
    const target = ev.currentTarget.parentNode.id
    if (source === target) { return false }

    element.classList.add('draggedOver')

    const quadrant = calculateQuadrant(ev)
    if (quadrant === 'top-left') {
      element.classList.add('topLeft')
    }

    // if (quadrant === 'top-right') {
    //    invalid (not used)
    // }

    if (quadrant === 'bottom-left') {
      element.classList.add('bottomLeft')
    }

    if (quadrant === 'bottom-right' || quadrant === 'top-right') {
      element.classList.add('bottomRight')
    }

    return false
  }

  const handleDragEnter = ev => {
    // ev.currentTarget.classList.add('draggedOver')
  }

  const handleDragLeave = ev => {
    removeClasses(ev)
  }

  const handleDrop = ev => {
    ev.stopPropagation() // stops the browser from redirecting
    const source = ev.dataTransfer.getData('text')
    const target = ev.currentTarget.parentNode.id

    if (source !== target) {
      let relation
      const quadrant = calculateQuadrant(ev)
      if (quadrant === 'top-left') {
        // before
        relation = 'before'
      }

      if (quadrant === 'bottom-left') {
        // after
        relation = 'after'
      }

      if (quadrant === 'bottom-right' || quadrant === 'top-right') {
        // child
        relation = 'child'
      }

      removeClasses(ev)

      // Drop logic calculated and completed, now time to ship it to the API
      onDropAction({
        requirement_ids: [source],
        target_requirement: target,
        target_relation_type: relation,
      })
    }
    return false
  }

  const onDropAction = payload => {
    store.dispatch('requirementsTable/handleDragMove', payload).then(r => {})
  }

  return {
    registerDragAndDrop,
    unregisterDragAndDrop,
  }
}
