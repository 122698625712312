const routes = [
  {
    path: '/create-model',
    name: 'create_model',
    component: () => import('@/views/Create/CreateModel.vue'),
    meta: {
      pageTitle: 'Create Model',
      auth: true,
      breadcrumb: [
        {
          text: 'Create Model',
          active: true,
        },
      ],
    },
  },
  {
    path: '/import-model-snapshot',
    name: 'import_model_snapshot',
    component: () => import('@/views/Create/ImportModelSnapshot.vue'),
    meta: {
      pageTitle: '',
      auth: true,
      breadcrumb: [
        {
          text: 'Import Model Snapshot',
          active: true,
        },
      ],
    },
  },
]

export default routes
