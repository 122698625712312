<template>
  <b-modal
    id="delete-note-modal"
    title="Delete Note"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @show="onShow"
    @hide="onHide"
    @ok="onSubmit"
  >
    <div class="d-inline-flex">
      <feather-icon icon="AlertTriangleIcon" class="text-danger mr-50 font-medium-4" />
      <h4>Delete note?</h4>
    </div>
    <p>This action cannot be undone.</p>

    <hr class="w-75">
    <strong v-sanitized-html="localNote.text" />
    <small class="text-muted select-all">UUID: {{ noteId }}</small>

    <template v-slot:modal-footer="{ok, cancel}">
      <!--
      To assist with avoiding accidental clicks on 'Submit', customise the action
      buttons for this delete modal so that cancel is on the right.
      -->
      <b-button variant="danger" @click="ok()">
        Delete
      </b-button>
      <b-button variant="flat-secondary" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import {
  computed,
  ref,
} from '@vue/composition-api'

export default {
  name: 'DeleteNoteModal',
  setup(props, context) {
    let etag
    const noteId = computed(() => store.state.notes.modal_target_note_id)
    const localNote = ref({ type: 'note', text: '' })

    const fetchNote = () => {
      store.dispatch('notes/fetchNote', noteId)
        .then(response => {
          if (response === null) { closeModal() }
          etag = response.etag
          localNote.value = response.data
        })
    }
    const closeModal = () => {
      etag = undefined
      localNote.value = { type: 'note', text: '' }
      context.root.$bvModal.hide('delete-note-modal')
    }

    const onShow = () => { fetchNote() }
    const onHide = () => { closeModal() }
    const onSubmit = () => {
      store.dispatch('notes/deleteNote', { noteId: noteId.value, etag })
        .finally(() => { closeModal() })
    }

    return {
      noteId,
      localNote,

      onShow,
      onHide,
      onSubmit,
    }
  },
}
</script>
