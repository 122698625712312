export default [
  {
    headerKey: 6,
    header: 'Settings',
  },
  // {
  //   title: 'Preferences',
  //   iconLibrary: 'font-awesome',
  //   icon: ['fa', 'cogs'],
  //   children: [
  //     // {
  //     //   title: 'General',
  //     //   route: 'settings_general',
  //     //   iconLibrary: 'font-awesome',
  //     //   icon: ['fa', 'cog'],
  //     // },
  //     // {
  //     //   title: 'Look & Feel',
  //     //   route: 'settings_application',
  //     //   iconLibrary: 'font-awesome',
  //     //   icon: ['fa', 'palette'],
  //     // },
  //   ],
  // },
  {
    title: 'Support',
    href: 'https://kompozition.atlassian.net/servicedesk/customer/portal/1',
    iconLibrary: 'font-awesome',
    icon: ['fas', 'question'],
  },
  {
    title: 'About',
    route: 'application_details',
    iconLibrary: 'font-awesome',
    icon: ['fa', 'info-circle'],
  },
]
