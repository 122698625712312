import DOMPurify from 'dompurify'

// Allow images to open in a new tab securely
DOMPurify.addHook('afterSanitizeAttributes', node => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})

export default {
  // Vue Hook Function
  inserted(el, binding) {
    const { value } = binding
    el.innerHTML = DOMPurify.sanitize(value)
  },
  update(el, binding) {
    const { value } = binding
    el.innerHTML = DOMPurify.sanitize(value)
  },
}
