<template>
<div>
  <p class="mt-0 mb-25 font-weight-bold text-primary" :hidden="noLabel">
    Technology Readiness Level
    <span class="font-small-2">(TRL)</span>
  </p>

  <div v-if="trl && trl !== 0" class="ml-50">
    <b-badge :class="resolveClass(trl)" id="trl-badge">
      {{ resolveTechReadinessLevel(trl).name }}
    </b-badge>
    <b-tooltip target="trl-badge" triggers="hover">
      {{ resolveTechReadinessLevel(trl).description }}
    </b-tooltip>
  </div>
  <div v-else class="ml-50">
    <b-badge class="trl0" id="trl-badge-2">
      Unevaluated
    </b-badge>
    <b-tooltip target="trl-badge-2" triggers="hover">
      TRL Unevaluated
    </b-tooltip>
  </div>
</div>
</template>

<script>
import store from '@/store'

export default {
  name: 'TechReadinessLevel',
  props: {
    trl: {
      type: Number,
      default: 0, // Unevaluated is 0
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const resolveTechReadinessLevel = value => {
      if (value) {
        return store.state.constants.trls.find(e => e.id === value)
      }
      return store.state.constants.defaultTrl
    }
    const resolveClass = value => (value > 0 && value < 10 ? `trl${value}` : 'trl0')
    return {
      resolveTechReadinessLevel,
      resolveClass,
    }
  },
}
</script>

<style scoped lang="scss">
.trl0 {
  background-color: lightsteelblue;
  color: #263238 !important;
}

.trl1 {
  background-color: #dc3545;
  color: white !important;
}

.trl2 {
  background-color: #dc3545;
  color: white !important;
}

.trl3 {
  background-color: #fd7e14;
  color: white !important;
}

.trl4 {
  background-color: #fd7e14;
  color: white !important;
}

.trl5 {
  background-color: palegoldenrod;
  color: #263238 !important;
}

.trl6 {
  background-color: palegoldenrod;
  color: #263238 !important;
}

.trl7 {
  background-color: palegoldenrod;
  color: #263238 !important;
}

.trl8 {
  background-color: lightseagreen;
  color: white !important;
}

.trl9 {
  background-color: #28a745;
  color: white !important;
}
</style>
