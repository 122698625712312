import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

const getDefaultState = () => ({
  composition_tree: {
    nodes: [
      {
        // Default data just to test graph function without API / Selected model
        abstract: 'True',
        deletable: false,
        id: 'c243571e-de60-45e7-a427-b92de2bfd4f2',
        model: '08e6df1c-6a2a-4cbf-987e-a85f9ee53877',
        multiplicity: '1',
        name: 'Empty Model',
        qualified_name: 'Empty Model',
      },
    ],
    edges: [],
    root: 'c243571e-de60-45e7-a427-b92de2bfd4f2',
  },
  components: [],
  selected_entity2: false,
  selected_attribute: false,
  selected_property: false,
  selected_constraint: false,
  selected_trl: false,
  selected_trl_date: null,
  selected_compliance: false,
  functions: [],
  function_decomp: [],
  objectives: [],
  capabilities: [],
  standards: [],
  entities: [],
  instances: [],
  performers: [],
  systems: [],
  comp_others: [],
  releases: [],
  cacheLastRefreshTime: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    allModelComponents: state => modelId => {
      Object.values(state[modelId]).flatMap(m => m.components)
    },
  },
  mutations: {
    SET_COMP_TREE: (state, tree) => {
      state.composition_tree = tree
    },
    SET_COMP_OTHERS: (state, others) => {
      state.comp_others = others
    },
    SET_SELECTED_ENTITY2: (state, et2) => {
      state.selected_entity2 = et2
    },
    SET_SELECTED_ATTRIBUTE: (state, attr) => {
      state.selected_attribute = attr
    },
    SET_SELECTED_PROPERTY: (state, prop) => {
      state.selected_property = prop
    },
    SET_SELECTED_CONSTRAINT: (state, attr) => {
      state.selected_constraint = attr
    },
    SET_SELECTED_TRL: (state, attr) => {
      state.selected_trl = attr
    },
    SET_SELECTED_TRL_DATE: (state, attr) => {
      state.selected_trl_date = attr
    },
    SET_SELECTED_COMPLIANCE: (state, attr) => {
      state.selected_compliance = attr
    },
    SET_ENTITIES: (state, entities) => {
      state.entities = entities
    },
    SET_COMPONENTS: (state, components) => {
      state.components = components
    },
    SET_FUNCTIONS: (state, fns) => {
      state.functions = fns
    },
    SET_CAPABILITIES_OBJ: (state, caps) => {
      state.capabilities = caps
    },
    SET_PERFORMERS: (state, perfs) => {
      state.performers = perfs
    },
    SET_SYSTEMS: (state, sys) => {
      state.systems = sys
    },
    SET_INSTANCES: (state, inst) => {
      state.instances = inst
    },
    SET_OBJECTIVES: (state, objs) => {
      state.objectives = objs
    },
    SET_STANDARDS: (state, stds) => {
      state.standards = stds
    },
    SET_FUNCTION_DECOMP: (state, fd) => {
      state.function_decomp = fd
    },
    SET_LINKED_MODEL_COMPONENTS(state, { modelId, subtype, components }) {
      if (!state[modelId]) state[modelId] = {}
      if (subtype !== '') {
        if (!state[modelId][subtype]) state[modelId][subtype] = { components: [] }
        state[modelId][subtype].components = components
        state[modelId][subtype].cacheLastRefreshTime = Date.now()
      } else {
        state[modelId].components = components
        state[modelId].cacheLastRefreshTime = Date.now()
      }
    },
    INVALIDATE_CACHE: state => {
      Object.assign(state, getDefaultState())
      state.cacheLastRefreshTime = null
    },
    CLEAR_ALL: state => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    clearComponents: ({ commit }) => { commit('INVALIDATE_CACHE') },
    getCompTreeData: ({ commit }, root) => {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .get(`/api/v2/domain_model/composition_tree${root ? `/${root}` : ''}`, {
          params: {
            model: modelId,
            partial: true,
          },
        })
        .then(({ data }) => {
          commit('SET_COMP_TREE', data)
        })
        .catch(e => console.error(e))
    },
    getCompTreeDataD3: ({ commit }, root) => {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .get(`/api/v2/domain_model/composition_tree${root ? `/${root}` : ''}`, {
          params: {
            model: modelId,
            partial: false,
          },
        })
        .then(({ data }) => {
          commit('SET_COMP_TREE', data)
        })
        .catch(e => console.error(e))
    },
    getOthers: ({ commit }, payload) => {
      axiosIns.post('/api/v2/domain_model/get_comp_others', payload)
        .then(({ data }) => {
          commit('SET_COMP_OTHERS', data)
        })
        .catch(e => console.error(e))
    },
    invalidateCache: ({ commit }) => {
      commit('INVALIDATE_CACHE')
    },
    getComponents: ({ rootState, commit }, payload) => {
      const forced = payload?.forced
      const { modelId } = router.currentRoute.params
      if (rootState.domainModel.cacheLastRefreshTime && rootState.domainModel.components.length > 0
        && Date.now() - rootState.domainModel.cacheLastRefreshTime < 60000 && !forced) {
        console.log('Components already in cache')
        return Promise.resolve()
      }
      rootState.domainModel.cacheLastRefreshTime = Date.now()
      return axiosIns
        .get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          commit('SET_COMPONENTS', data)
          const functions = data.filter(cpt => (
            cpt.labels.includes('Function')
            || cpt.labels.includes('Capability')
            || cpt.labels.includes('Activity')))
          commit('SET_FUNCTIONS', functions)
          const resources = data.filter(cpt => cpt.labels.includes('Resource'))
          commit('SET_ENTITIES', resources)
          const performers = data.filter(cpt => (
            cpt.labels.includes('Performer')
            || cpt.labels.includes('System')
            || cpt.labels.includes('Service')
            || cpt.labels.includes('Node')))
          commit('SET_PERFORMERS', performers)
          const systems = data.filter(cpt => (cpt.labels.includes('System') || cpt.labels.includes('Service')))
          commit('SET_SYSTEMS', systems)
          const objectives = data.filter(cpt => cpt.labels.includes('Objective'))
          commit('SET_OBJECTIVES', objectives)
          const capabilities = data.filter(cpt => cpt.labels.includes('Capability'))
          commit('SET_CAPABILITIES_OBJ', capabilities)
          const standards = data.filter(cpt => (
            cpt.labels.includes('Standard')
            || cpt.labels.includes('Reference')
            || cpt.labels.includes('Agreement')
          ))
          commit('SET_STANDARDS', standards)
        })
        .catch(e => console.error(e))
    },
    getComponentsForModel: async ({ commit, rootState }, { modelId, subtype }) => {
      if (router.currentRoute.params.modelId === modelId || !modelId) {
        if (rootState.domainModel[subtype]) {
          if (rootState.domainModel.cacheLastRefreshTime && rootState.domainModel.components.length > 0
            && Date.now() - rootState.domainModel.cacheLastRefreshTime < 60000) {
            console.log('Subtype Components already in cache for local model')
            return Promise.resolve()
          }
        }
      }
      if (modelId && router.currentRoute.params.modelId !== modelId) {
        // check cache for linked models
        if (rootState.domainModel[modelId] && rootState.domainModel[modelId][subtype]) {
          if (rootState.domainModel[modelId][subtype].cacheLastRefreshTime
            && rootState.domainModel[modelId][subtype].components.length > 0
            && Date.now() - rootState.domainModel[modelId][subtype].cacheLastRefreshTime < 60000) {
            console.log('Subtype Components already in cache for linked model')
            return Promise.resolve()
          }
        }
        console.log('Cache miss on Subtype Components for linked model', modelId, subtype)
        const components = await coreService.modelApi.getAllComponents(modelId, subtype)
        commit('SET_LINKED_MODEL_COMPONENTS', { modelId, subtype, components })
      }
      return Promise.resolve()
    },
    getComponentsSimple: ({ commit, rootState }) => {
      const { modelId } = router.currentRoute.params
      if (rootState.domainModel.cacheLastRefreshTime && rootState.domainModel.components.length > 0
        && Date.now() - rootState.domainModel.cacheLastRefreshTime < 60000) {
        console.log('Components already in cache')
        return Promise.resolve()
      }
      rootState.domainModel.cacheLastRefreshTime = Date.now()
      return axiosIns
        .get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          commit('SET_COMPONENTS', data)
          const functions = data.filter(cpt => cpt.labels.includes('Function'))
          commit('SET_FUNCTIONS', functions)
          const resources = data.filter(cpt => cpt.labels.includes('Resource'))
          commit('SET_ENTITIES', resources)
          const performers = data.filter(cpt => cpt.labels.includes('Performer'))
          commit('SET_PERFORMERS', performers)
          const systems = data.filter(cpt => (cpt.labels.includes('System') || cpt.labels.includes('Service')))
          commit('SET_SYSTEMS', systems)
          const objectives = data.filter(cpt => cpt.labels.includes('Objective'))
          commit('SET_OBJECTIVES', objectives)
          const capabilities = data.filter(cpt => cpt.labels.includes('Capability'))
          commit('SET_CAPABILITIES_OBJ', capabilities)
          const standards = data.filter(cpt => (
            cpt.labels.includes('Standard')
            || cpt.labels.includes('Reference')
            || cpt.labels.includes('Agreement')
          ))
          commit('SET_STANDARDS', standards)
        })
        .catch(e => console.error(e))
    },
    getNextLevelData: ({ commit, state }, root) => {
      const { modelId } = router.currentRoute.params
      const newTree = {
        nodes: [],
        edges: [],
      }
      return axiosIns
        .get(`/api/v2/domain_model/next_level${root ? `/${root}` : ''}`, { params: { model: modelId } })
        .then(({ data }) => {
          // Merge the new level with the current tree
          const tree = state.composition_tree
          const treeNodes = tree.nodes.map(i => i.id)
          // console.log('[vuex/domainModel::getNextLevelData] Merge: ', tree, ' and ', data)
          // console.log('[vuex/domainModel::getNextLevelData] treeNodes: ', treeNodes)
          data.nodes.forEach(n => {
            // console.log('[vuex/domainModel::getNextLevelData] Checking: ', n.id)
            if (!(treeNodes.includes(n.id))) {
              // console.log('[vuex/domainModel::getNextLevelData] Adding Node:', n, ' to ', tree.nodes)
              tree.nodes.push(n)
              newTree.nodes.push(n)
              // console.log('[vuex/domainModel::getNextLevelData] After Adding Node:', tree.nodes)
              data.edges.forEach(e => {
                if (e.target === n.id) {
                  // console.log('[vuex/domainModel::getNextLevelData] Adding Edge:', e)
                  tree.edges.push(e)
                  newTree.edges.push(n)
                }
              })
            }
          })
          tree.newTree = newTree
          // console.log('[vuex/domainModel::getNextLevelData] Commiting new tree: ', tree)
          commit('SET_COMP_TREE', tree)
        })
        .catch(e => console.error(e))
    },
    updateComponent: ({ commit }, payload) => {
      const { modelId } = router.currentRoute.params
      const compId = payload.id
      const compData = payload.data
      payload.model = modelId
      return axiosIns
        .put(`/api/v2/domain_model/composition/${compId}`, compData, { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Response from Update: ', data)
          commit('INVALIDATE_CACHE')
          return data
        })
        .catch(r => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not update node',
              text: r.response.data.detail || 'Failed to update component',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          console.error(r)
        })
    },
    selectEntity2: ({ commit }, entityId) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      if (entityId) {
        return coreService
          .get(`v1/component/${entityId}`, config)
          .then(response => {
            commit('SET_SELECTED_ENTITY2', response.data)
            resolve()
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
      return null
    }),
    selectAttribute: ({ commit }, attr) => {
      commit('SET_SELECTED_ATTRIBUTE', attr)
    },
    selectProperty: ({ commit }, prop) => {
      commit('SET_SELECTED_PROPERTY', prop)
    },
    selectConstraint: ({ commit }, attr) => {
      commit('SET_SELECTED_CONSTRAINT', attr)
    },
    selectTRL: ({ commit }, trl) => {
      commit('SET_SELECTED_TRL', trl)
    },
    selectTRLDate: ({ commit }, date) => {
      commit('SET_SELECTED_TRL_DATE', date)
    },
    selectCompliance: ({ commit }, attr) => {
      commit('SET_SELECTED_COMPLIANCE', attr)
    },
    mergeComponent: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/merge_components', payload, { params: { model: modelId } })
        .then(({ data }) => {
          console.debug('Merge complete: ')
          resolve()
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not merge nodes',
              text: error.response.data.detail || 'Failed to merge component',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          reject(error)
        })
    }),
    moveComponent: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/move_components', payload, { params: { model: modelId } })
        .then(({ data }) => {
          console.debug('Move complete: ', data)
          commit('INVALIDATE_CACHE')
          resolve()
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not move node',
              text: error.response.data.detail || 'Could not move node',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          reject(error)
        })
    }),
    copyComponent: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/copy_component', payload, { params: { model: modelId } })
        .then(({ data }) => {
          commit('INVALIDATE_CACHE')
          console.debug('Copy complete: ', data)
          resolve(data)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not copy node',
              text: error.response.data.detail || 'Failed to copy component',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          reject(error)
        })
    }),
    generateFunctionalRequirements: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/generate_requirements_from_fn', payload, { params: { model: modelId } })
        .then(({ data }) => {
          // dispatch('requirements/invalidateCache')
          console.debug('Generate Requirements complete. ')
        })
        .catch(error => {
          console.error(error)
        })
    },
    generateQualityAttributeRequirements: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/generate_constraint_requirements_from_component', payload, { params: { model: modelId } })
        .then(({ data }) => {
          // dispatch('requirements/invalidateCache')
          console.debug('Generate Requirements complete. ')
        })
        .catch(error => {
          console.error(error)
        })
    },
    generateInterfaceRequirements: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      console.debug('Calling generate_interface_requirements with: ', payload)
      axiosIns.post('/api/v2/domain_model/generate_requirements_from_if', payload, { params: { model: modelId } })
        .then(({ data }) => {
          dispatch('requirements/invalidateCache')
          console.debug('Generate Requirements complete. ')
        })
        .catch(error => {
          console.error(error)
        })
    },
    allocateFunction: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      console.log('Calling allocate_function with: ', payload)
      axiosIns.post('/api/v2/domain_model/allocate_function', payload, { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Function Allocation Complete. ')
          dispatch('selectEntity2', payload.function)
        })
        .catch(error => {
          console.error(error)
        })
    },
    constrainFunction: ({ dispatch }, payload) => {
      const { modelId } = router.currentRoute.params
      payload.model = modelId
      axiosIns.post('/api/v2/domain_model/constrain_function', payload, { params: { model: modelId } })
        .then(({ data }) => {
          console.log('Function Constraint Complete. ')
          dispatch('selectEntity2', payload.function)
        })
        .catch(error => {
          console.error(error)
        })
    },
    generateConstraintRequirements: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .post(
          '/api/v2/domain_model/generate_requirements_from_constraint',
          {
            componentId: payload.componentId,
            constraintId: payload.constraintId,
            targetSpecId: payload.targetSpecId.id,
            targetReqId: payload.targetReqId,
            relationToTarget: payload.relationToTarget,
          },
          { headers: { 'Model-Id': modelId } },
        )
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Generated ${response.data.length} constraint requirements`,
              text: `For component: ${state.selected_entity2.context.details.name}.${state.selected_constraint.name} in ${payload.targetSpecId.title}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to generate constraint requirements',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    generateComplianceRequirements: ({ state }, payload) => {
      const { modelId } = router.currentRoute.params
      return axiosIns
        .post(
          '/api/v2/domain_model/generate_requirements_from_compliance',
          {
            componentId: payload.componentId,
            complianceId: payload.complianceId,
            targetSpecId: payload.targetSpecId.id,
            targetReqId: payload.targetReqId,
            relationToTarget: payload.relationToTarget,
          },
          { headers: { 'Model-Id': modelId } },
        )
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generated compliance requirement',
              text: `For component: ${state.selected_entity2.context.details.name}.${state.selected_compliance.name} in ${payload.targetSpecId.title}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          console.error(error)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to generate compliance requirement',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    getFunctionalDecomposition: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      axiosIns.get('/api/v2/domain_model/get_fn_decomp', { params: { model: modelId } })
        .then(({ data }) => {
          const t = data
          let i = 0
          const root = t.nodes[t.root]
          const getChildren = nodeId => t.edges.filter(e => e.source === nodeId)
            .map(x => x.target)
          const recursiveFunction = tree => {
            const c = getChildren(tree)
            const returnObj = []
            c.forEach(e => {
              i++
              if (getChildren(e).length > 0) {
                returnObj.push({
                  id: e,
                  name: t.nodes.find(x => x.id === e).name,
                  children: recursiveFunction(e),
                })
              } else {
                returnObj.push({
                  id: e,
                  name: t.nodes.find(x => x.id === e).name,
                })
              }
            })
            return returnObj
          }
          if (t !== undefined && root) {
            commit('SET_FUNCTION_DECOMP', [{
              id: t.root,
              name: t.nodes.find(x => x.id === t.root).name,
              children: recursiveFunction((t.root)),
            }])
          } else {
            commit('SET_FUNCTION_DECOMP', [])
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
