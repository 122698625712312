import navGroupBase from '@/navigation/vertical'
import navGroupAdmin from '@/navigation/vertical/navGroupAdmin'
import navGroupCreate from '@/navigation/vertical/navGroupCreate'
import navGroupProjectBuilder from '@/navigation/vertical/navGroupProject'
import navGroupSettings from '@/navigation/vertical/navGroupSettings'
import router from '@/router'
import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    items: navGroupBase.concat(navGroupCreate).concat(navGroupSettings),
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    REBUILD_NAV_LINKS(state, isAdmin) {
      /**
       * Rebuild the navigation menu with the given groups list
       * @param {Array} ...groups - Array of navGroup lists
       */
      state.items = navGroupBase
      const { modelId } = router.currentRoute.params
      const storedModelId = sessionStorage.getItem('kompozition-workspace')

      if (modelId || storedModelId) {
        // Model workspace is SET
        state.items = state.items.concat(navGroupProjectBuilder(storedModelId || modelId))
      } else {
        // Model Workspace is UNSET
        state.items = state.items.concat(navGroupCreate)
      }

      // Include settings links
      state.items = state.items.concat(navGroupSettings)

      // Include administrator links
      if (isAdmin) {
        state.items = state.items.concat(navGroupAdmin)
      }
    },
  },
  actions: {
    navigationWorkspace({ rootState, commit }) {
      const isAdmin = rootState.auth.roles !== null ? rootState.auth.roles.includes('administrator') : false
      commit('REBUILD_NAV_LINKS', isAdmin)
    },
  },
}
